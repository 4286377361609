import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Switch,
  Autocomplete,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert,
} from '@mui/material';
import { createUser, updateUser, getCodesFor, getCountries, getUser, getComplianceProfile } from '../../Services/api';
import XRegExp from 'xregexp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useUser } from '../../UserContext'; // Importar el contexto del usuario
import { red } from '@mui/material/colors';


const UsersForm = ({ user, onSave, onCancel }) => {

  const { user: currentUser } = useUser(); // Obtener el usuario del contexto

  //console.log(useUser(),"343");
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    type: '',
    nick: '',
    first_name: '',
    last_name: '',
    nationality: '',
    gender: 'M',
    activity_id: '',
    marital_id: '',
    phone: '',
    is_pep: false,
    compliance_profile_id:''
  });

  const [activities, setActivities] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const [password, setPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
// const [snackbarOpen, setSnackbarOpen] = useState(false); // Para mostrar el snackbar


  const isViewer = currentUser && currentUser.permissions && 
  currentUser.permissions.some(permission => permission.tag === 'CORE_VIEWER' || permission.tag === 'CORE_FEES');
const isFees = currentUser && currentUser.permissions && currentUser.permissions.some(permission => permission.tag === 'CORE_FEES');
const isSuperAdmin = user && user.permissions && user.permissions.some(permission => permission.tag === 'SUPERADMIN');
const [selectedProfile, setSelectedProfile] = useState(null);
const [complianceProfiles, setComplianceProfiles] = useState([]);


const hasPermission = (tag) => {
  

  if (currentUser && currentUser.permissions && currentUser.permissions.some(permission => permission.tag === 'SUPERADMIN')) {
    return true; 
  }
  return currentUser && currentUser.permissions && currentUser.permissions.some(permission => permission.tag === tag);
};


  // Función para obtener perfiles de cumplimiento
  const fetchComplianceProfiles = async () => {
    try {
      const profiles = await getComplianceProfile();
      setComplianceProfiles(profiles);
    } catch (error) {
      console.error('Error fetching compliance profiles:', error);
    }
  };


  const handleResetPassword = async () => {
    try {
      const updatedData = { ...formData, password: 'asdf1234' };
  
      // Llama a la API para actualizar el usuario con la nueva contraseña
      await updateUser(user.id, updatedData);
  
      // Muestra una notificación indicando que la contraseña fue actualizada
      setSnackbarMessage('Password has been reset successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
  
      // Cierra el modal
      setResetPasswordModalOpen(false);
    } catch (error) {
      console.error('Error resetting password:', error);
      setSnackbarMessage('Error resetting password');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
  
      // Cierra el modal en caso de error
      setResetPasswordModalOpen(false);
    }
  };
  

  useEffect(() => {
    if (snackbarOpen) {
      const timer = setTimeout(() => {
        setSnackbarOpen(false); // Cierra el snackbar después de un tiempo
      }, 4000);
      return () => clearTimeout(timer); // Limpia el timeout cuando el componente se desmonte o cambie
    }
  }, [snackbarOpen]);

  useEffect(() => {
    // Este effect se ejecutará cada vez que cambie el estado del snackbar

  }, [snackbarOpen, snackbarMessage, snackbarSeverity]);  // Los estados a los que se debe suscribir el effect

  


  useEffect(() => {
    fetchComplianceProfiles(); // Cargar perfiles al principio
  }, []);


  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  




  const handleBlur = () => {


    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        if (!snackbarOpen) { // Verifica si el snackbar no está ya abierto
          setSnackbarMessage('Passwords do not match');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } else {
        // Si las contraseñas coinciden, puedes decidir si cerrar el Snackbar aquí
        setSnackbarOpen(false);
      }
    }
  };
  
  
  

  // console.log('Is current user a VIEWER?', isViewer);
  // Fetch user data when the user prop changes
  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const response = await getUser(user.id);
  
          // Asigna el perfil de cumplimiento si coincide con el ID guardado en la base de datos
          const profileId = response.data.compliance_profile_id;
          const profile = complianceProfiles.find((p) => p.id === profileId);
          setSelectedProfile(profile || null);
  
          // Establece el resto de los datos del usuario en el formulario
          setFormData({
            email: response.data.email || '',
            password: '',
            type: response.data.type || '',
            nick: response.data.nick || '',
            first_name: response.data.first_name || '',
            last_name: response.data.last_name || '',
            nationality: response.data.nationality || '',
            gender: response.data.gender || 'M',
            activity_id: response.data.activity_id || '',
            marital_id: response.data.marital_id || '',
            phone: response.data.phone || '',
            is_pep: Boolean(response.data.is_pep),
            compliance_profile_id: profileId || '',
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // Reinicia el formulario si no hay usuario seleccionado
        setFormData({
          email: '',
          password: '',
          type: '',
          nick: '',
          first_name: '',
          last_name: '',
          nationality: '',
          gender: 'M',
          activity_id: '',
          marital_id: '',
          phone: '',
          is_pep: false,
          compliance_profile_id: '',
        });
      }
    };
  
    fetchUserData();
  }, [user, complianceProfiles]); // Añade complianceProfiles como dependencia
  

  // Fetch codes for activities, marital statuses, and countries
  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const activitiesData = await getCodesFor('ACT');
        const maritalStatusesData = await getCodesFor('MAR');
        const countriesData = await getCountries();
        setActivities(activitiesData);
        setMaritalStatuses(maritalStatusesData);
        setCountries(countriesData.data);
      } catch (error) {
        console.error('Error fetching codes:', error);
      }
    };
    fetchCodes();
  }, []);

  const handleChange = (e) => {

    if(e.target.type === 'password')
    setPassword(e.target.value);


    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const validate = () => {
    const newErrors = {};

    // Validaciones replicando las reglas de Laravel
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (formData.password && (formData.password.length < 6 || formData.password.length > 255)) {
      newErrors.password = 'Password must be between 6 and 255 characters.';
    }

    if (!formData.type || !['B', 'C'].includes(formData.type)) {
      newErrors.type = 'Type must be B or C.';
    }

    if (formData.first_name && !XRegExp('^[\\p{L}\\s.]+$').test(formData.first_name)) {
      newErrors.first_name = 'First name can only contain letters and spaces.';
    }

    if (formData.last_name && !XRegExp('^[\\p{L}\\s.]+$').test(formData.last_name)) {
      newErrors.last_name = 'Last name can only contain letters and spaces.';
    }
    
    if (formData.nationality && !XRegExp('^[\\p{L}\\s.]+$').test(formData.nationality)) {
      newErrors.nationality = 'Nationality can only contain letters and spaces.';
    }

    if (formData.gender && !['M', 'F'].includes(formData.gender)) {
      newErrors.gender = 'Gender must be M or F.';
    }

    if (formData.phone && (!/^\d+$/.test(formData.phone) || formData.phone.length > 191)) {
      newErrors.phone = 'Phone must be a number and up to 191 digits.';
    }

  // Validación de contraseñas
  if (formData.password && (formData.password.length < 6 || formData.password.length > 255)) {
    newErrors.password = 'Password must be between 6 and 255 characters.';
  }
  if (password && confirmPassword && password !== confirmPassword) {
    newErrors.password = 'Passwords do not match.';
  }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const submitData = { ...formData };

        if (!submitData.password) {
          delete submitData.password;
        }

        
        if (user) {
          await updateUser(user.id, submitData);
        } else {
          await createUser(submitData);
        }

        setSnackbarMessage('User saved successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onSave();
      } catch (error) {
        console.error('Error saving user:', error);
        setSnackbarMessage('Error saving user');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, backgroundColor: 'white', p: 3, borderRadius: 2, boxShadow: 1 }}>
<TextField
  name="email"
  label="Email"
  value={formData.email}
  onChange={handleChange}
  required
  fullWidth
  error={!!errors.email}
  helperText={errors.email}
  sx={{
    mb: 2,
    "& .MuiInputBase-input": {
      backgroundColor: "#f0eeee", // Fondo gris
      color: "gray", // Texto gris para parecer deshabilitado
    },
    "& .Mui-disabled": {
      backgroundColor: "lightgray",
    },
    "& .MuiInputLabel-root": {
      color: "gray", // Etiqueta en gris
    },
  }}
  InputProps={{
    readOnly: true, // Solo lectura si el usuario no puede editarlo
  }}
  autoComplete="off"
/>

{hasPermission('CORE_USR_PASS_CHANGE') ? (
   <>
  
<input type="text" style={{ display: 'none' }} />
<TextField
  name="password"
  label="Password"
  type="password"
  value={formData.password || ''}  // Si no hay valor, se muestra vacío
  onChange={handleChange}
  placeholder="Enter a new password if you want to change it"
  fullWidth
  sx={{ mb: 2 }}
  error={!!errors.password}
  helperText={errors.password}
  required={!user}
  //  onChange={handlePasswordChange}
  onBlur={handleBlur} // Agregar el 'onBlur' aquí
  InputProps={{
    readOnly: isViewer, // Solo lectura si el usuario es CORE_VIEWER
  }}
  autoComplete="new-password" // Alternativamente, usa "new-password" para campos de contraseña
/>


<TextField
  label="Confirm Password"
  type="password"
  fullWidth
    autoComplete="new-password"
  sx={{ mb: 2 }}
  value={confirmPassword}
  onChange={handleConfirmPasswordChange}
  onBlur={handleBlur}
/>
</>
) : null}


{hasPermission('CORE_USR_PASS_RESET') ? (
<Box sx={{ display: 'flex', gap: 2, mb: '15px', justifyContent: 'flex-start', alignItems: 'center' }}>
    <Button
      sx={{
        width: '170px',
        minWidth: '100px',
        padding: '6px 12px',
      }}
      variant="contained"
      color="secondary"
      onClick={() => setResetPasswordModalOpen(true)} // Abre el modal
    >
      Reset Password
    </Button>
</Box>
) : null}





{isViewer ? (
  <TextField
    label="Type"
    value={formData.type === 'B' ? 'Business' : formData.type === 'C' ? 'Consumer' : ''}
    fullWidth
    sx={{ mb: 2 }}
    InputProps={{
      readOnly: true,
    }}
  />
) : (
<FormControl component="fieldset" sx={{ mb: 2 }}>
  <RadioGroup
    name="type"
    value={formData.type}
    onChange={handleChange} // Usando la misma función de manejo del cambio
    row
  >
    <FormControlLabel
      value="B"
      control={<Radio disabled={isViewer} />} // Deshabilitado si es solo vista
      label="Business"
    />
    <FormControlLabel
      value="C"
      control={<Radio disabled={isViewer} />} // Deshabilitado si es solo vista
      label="Consumer"
    />
  </RadioGroup>
  {errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}
</FormControl>
)}






      <TextField
        name="nick"
        label="Nick"
        value={formData.nick}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.nick}
        helperText={errors.nick}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <TextField
        name="first_name"
        label="First Name"
        value={formData.first_name}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.first_name}
        helperText={errors.first_name}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <TextField
        name="last_name"
        label="Last Name"
        value={formData.last_name}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.last_name}
        helperText={errors.last_name}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
  
  
          {isViewer ? (
          <TextField
            label="Nationality"
            value={countries.find((country) => country.id === formData.nationality)?.name || ''}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
        <Autocomplete
          options={countries}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => setFormData({ ...formData, nationality: newValue ? newValue.id : '' })}
          value={countries.find((country) => country.id === formData.nationality) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nationality"
              fullWidth
              error={!!errors.nationality}
              helperText={errors.nationality}
              sx={{ mb: 2 }}
            />
          )}
        />
        )}


      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <RadioGroup name="gender" value={formData.gender} onChange={handleChange} row>
          <FormControlLabel value="M" control={<Radio disabled={isViewer} />} label="Male" />
          <FormControlLabel value="F" control={<Radio disabled={isViewer} />} label="Female" />
        </RadioGroup>
        {errors.gender && <p style={{ color: 'red' }}>{errors.gender}</p>}
      </FormControl>


          {isViewer ? (
      <TextField
        label="Activity"
        value={activities.find((activity) => activity.id === formData.activity_id)?.description || ''}
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          readOnly: true,
        }}
      />
    ) : (
      <Autocomplete
        options={activities}
        getOptionLabel={(option) => option.description}
        onChange={(event, newValue) => setFormData({ ...formData, activity_id: newValue ? newValue.id : '' })}
        value={activities.find((activity) => activity.id === formData.activity_id) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Activity"
            fullWidth
            sx={{ mb: 2 }}
          />
        )}
      />
    )}


    {isViewer ? (
      <TextField
        label="Marital Status"
        value={maritalStatuses.find((status) => status.id === formData.marital_id)?.description || ''}
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          readOnly: true,
        }}
      />
    ) : (
      <Autocomplete
        options={maritalStatuses}
        getOptionLabel={(option) => option.description}
        onChange={(event, newValue) => setFormData({ ...formData, marital_id: newValue ? newValue.id : '' })}
        value={maritalStatuses.find((status) => status.id === formData.marital_id) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Marital Status"
            fullWidth
            sx={{ mb: 2 }}
          />
        )}
      />
    )}


<Autocomplete
  sx={{ mb: 4 }}
  options={complianceProfiles}
  value={selectedProfile}
  getOptionLabel={(option) => `${option.id} - ${option.description}`}
  onChange={(event, newValue) => {
    setSelectedProfile(newValue);
    setFormData({ ...formData, compliance_profile_id: newValue ? newValue.id : '' });
  }}
  disabled={!hasPermission('CORE_USR_COMPLIANCE_PRF_UPD')}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Compliance Profile"
      variant="outlined"
      sx={{ width: '300px', height: '40px', top: '15px', marginBottom: '16px' }}
    />
  )}
/>



      <TextField
        name="phone"
        label="Phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
        sx={{ mb: 2 }}
        error={!!errors.phone}
        helperText={errors.phone}
        InputProps={{
          readOnly: isViewer, // Solo lectura si el usuario es VIEWER
        }}
      />
      <FormControlLabel
        control={<Switch checked={formData.is_pep} onChange={handleChange} name="is_pep" disabled={isViewer} />}
        label="Is PEP"
      />



<Box sx={{ display: 'flex', gap: 2, marginTop: '30px' }}>
  {/* Mostrar el botón solo si no es VIEWER ni tiene permisos de FEES */}

  { hasPermission('CORE_USR_ADDRESS_UPD')? (
    <Button type="submit" variant="contained" color="primary">
      {user ? 'Update' : 'Create'} 
    </Button>
  ) : null}
  {/*
  <Button variant="contained" color="secondary" onClick={onCancel}>
    Cancel
  </Button>   */}

  </Box>

  




  {resetPasswordModalOpen && (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1300,
    }}
  >
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '8px',
        maxWidth: '400px',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <p>Are you sure you want to reset the password?</p>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword} // Llama a la función para resetear la contraseña
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setResetPasswordModalOpen(false)} // Cierra el modal sin hacer nada
        >
          Cancel
        </Button>
      </Box>
    </Box>
  </Box>
)}




      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
     //   onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
       //   onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: 'white' }} />,
            error: <ErrorIcon sx={{ color: 'white' }} />,
          }}
          sx={{
            top: { xs: '110px', sm: '110px' },
            width: '100%',
            backgroundColor: snackbarSeverity === 'success' ? '#3FAE10' : '#f44336',
            color: 'white',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UsersForm;
